import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import closeMenu from '../../Images/closeMenu.png'
import Banner from '../../Images/Banner.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import locationimg from '../../Images/location.png'
import Logo1 from '../../ImagesLogos/Logo1.png'
import './Header.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import Arrow from '../../Images/downArrow.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function Header({ CitiePhone, CitiesData, showMenu, setShowPopUp, setShowMenu, navigateTo, WebSitePages }) {
    const [header, setHeader] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div className="Header">
            {/* HeaderTopComputer */}
            <div className="HeaderTopComputer">
                <div className="Header2" >
                    <div className="Header2Logo">
                        {/* <img src={Banner} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," /> */}
                        <img src={Logo1} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," />
                    </div>
                    <div className="Header2LogoName">
                        <a href="/">BHO Chimney Sweep</a>
                        <p>Your Local Chimney Sweep Company</p>
                    </div>
                </div>

                <div className="Header1">

                    {location.pathname == "/local" || location.pathname == "/book" ?
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <a onClick={() => {
                                    window.scrollTo(0, 0)
                                    navigate(`/local${location.search}`)
                                }
                                }>HOME PAGE</a>
                            </div>
                        </div>
                        :
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <a href={`/`}>HOME</a>
                            </div>
                        </div>
                    }

                    {location.pathname == "/local" || location.pathname == "/book" ?
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                {location.pathname == "/book" ?
                                    <a href={`/local${location.search}#ourservices`}>OUR SERVICES</a>
                                    :
                                    <a onClick={() => window.location.hash = 'ourservices'}>OUR SERVICES</a>
                                }
                            </div>
                        </div>
                        :
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <h1>Services</h1>
                                <img src={Arrow} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," />
                            </div>

                            <div className="Header1BoxMenu">
                                {AreaData.map((A, index) =>
                                    <a key={index} href={`${A.Path}${location.search}`} className="Header1BoxMenuBox">
                                        {A.TitleShort}
                                    </a>
                                )}
                            </div>
                        </div>
                    }

                    {location.pathname == "/local" || location.pathname == "/book" ?
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                {location.pathname == "/book" ?
                                    <a href={`/local${location.search}#reviews`}>REVIEWS &gt;</a>
                                    :
                                    <a onClick={() => window.location.hash = 'reviews'}>REVIEWS &gt;</a>
                                }
                            </div>
                        </div>
                        :
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <a href={`/reviews/local`}>REVIEWS &gt;</a>
                            </div>
                        </div>
                    }

                    {location.pathname == "/local" || location.pathname == "/book" ?
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                {location.pathname == "/book" ?
                                    <a href={`/local${location.search}#contact`}>CONTACT US</a>
                                    :
                                    <a onClick={() => window.location.hash = 'contact'}>CONTACT US</a>
                                }
                            </div>
                        </div>
                        :
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <a href={`/contact${location.search}`}>Contact Us</a>
                            </div>
                        </div>
                    }

                    {location.pathname == "/local" || location.pathname == "/book" ?
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                {location.pathname == "/book" ?
                                    <a href={`/local${location.search}#about`}>ABOUT US</a>
                                    :
                                    <a onClick={() => window.location.hash = 'about'}>ABOUT US</a>
                                }
                            </div>
                        </div>
                        :
                        null
                    }


                    {location.pathname == "/local" || location.pathname == "/book" ?
                        null
                        :
                        <div className="Header1Box">
                            <div className="Header1BoxHedaer">
                                <a href={`/blog${location.search}`}>Blog</a>
                            </div>
                        </div>
                    }


                </div>
            </div>
            {/* HeaderTopPhone */}
            <div className="HeaderTopPhone">
                <div className="Header2" >
                    <div className="Header2Logo">
                        <img src={Logo1} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," />
                    </div>
                    <div className="Header2LogoName">
                        <a href="/">BHO Chimney Sweep</a>
                        <p>Your Local Chimney Company</p>
                    </div>
                </div>
                <div className='Header3'>
                    <div className="Header2Circle" onClick={() => { setShowPopUp(true) }}>
                        <div className='circle'>
                            <span className='hdhs1'></span>
                            <span className='hdhs2'></span>
                        </div>
                        <h3>Open Now</h3>
                    </div>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(0)", "width": "30px" } : { "filter": "invert(1)", "width": "30px" }}
                            src={closeMenu} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO,"
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(0)" } : { "filter": "invert(1)" }}
                            src={leftMenu} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO,"
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>
            {/* HeaderBottom */}
            <div className="HeaderBottom">
                {location.pathname == "/local" || location.pathname == "/book" ?
                    <div className='Header2'>
                        <div className="Header2Location">
                            <img src={locationimg} alt="" />
                            <h1>{CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Local"}</h1>
                        </div>
                        <div className="Header2Circle">
                            <div className='circle'>
                                <span className='hdhs1'></span>
                                <span className='hdhs2'></span>
                            </div>
                            <h3>Available Now</h3>
                        </div>
                    </div>
                    :
                    <div className='Header2'>
                        <div className="Header2Box" onClick={() => navigateTo("contact")}>
                            <h2>CONTACT US</h2>
                        </div>
                        <div className="Header2Box" onClick={() => navigateTo("blog")}>
                            <h2 >BLOG</h2>
                        </div>
                    </div>
                }


                <div className="Header1Main">
                    <div className='Header1'>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE <span>&nbsp;ONLINE</span><img src={book} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," /></a>
                    </div>
                    <div className='Header1'>
                        <a target='_blank' href={`tel:${CitiePhone}`}>CALL <span>&nbsp;{CitiePhone}</span><img src={telephone} alt="Chimney Sweep | Chimney Sweep Repair | Chimney Sweep Services | Chimney Sweep Inspection In BHO," /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
