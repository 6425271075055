import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './PopUp.scss';
import book from '../../Images/book.png'
import Image1Logo from '../../ImagesLogos/Image1Logo.png'
import closeMenu from '../../Images/closeMenu.png'
import Banner2Logo from '../../ImagesLogos/Banner2Logo.png'

export default function PopUp({ CitiePhone, CitiesData, setShowPopUp }) {
    const navigate = useNavigate()
    const location = useLocation()
    const url = new URL(window.location.href);

    return (
        <div className="PopUp">
            <div className="MainBanner">
                <div className="MainBannerHeader">
                    <img onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        setShowPopUp(false)
                    }
                    } src={closeMenu} alt="Chimney Sweep In BHO, | Chimney Repair In BHO, | Chimney Services In BHO, | Chimney Inspection In BHO," />
                </div>
                <div className="MainBannerLeft">
                    <div className="MainBannerLeftLogo">
                        <img src={Banner2Logo} alt="" />
                        {/* <h5>BHO CHIMNEY SWEEP</h5> */}
                        <h6>{CitiesData ? CitiesData.Full.toUpperCase() : "5 Star Chimney Company"}</h6>
                    </div>
                    <h1>Schedule Your Chimney Service Today!</h1>
                    <h3><span>{url.searchParams.get('kd') ? url.searchParams.get('kd').includes('near') ? url.searchParams.get('kd').replace('near me', '•') : url.searchParams.get('kd') + "," : ""}</span> Chimney Inspections, Repair And Cleaning Near Me</h3>
                    <h2>Don't wait until it's too late! Ensure your chimney is safe and functioning efficiently.</h2>
                    <a href={`tel:${CitiePhone}`} onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        setShowPopUp(false)
                    }}>Click To Call!</a>
                    <a onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        navigate(`/book${location.search}`)
                        setShowPopUp(false)
                    }}>SCHEDULE ONLINE<img src={book} alt="Chimney Cleaning Near Me In BHO" /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={Image1Logo} alt="Chimney Sweep In BHO, | Chimney Repair In BHO, | Chimney Services In BHO, | Chimney Inspection In BHO," />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
        </div>
    )
}
